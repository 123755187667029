@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face{
    font-family: 'Playlist-script';
    src:url('../../fonts/PlaylistScript.ttf') format('truetype');
    font-style: normal, italic, oblique;
    font-weight: normal;
}

.teamWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.teamWrapper > .titleWrapper
{
    text-align: center;
    padding: 30px;
    z-index: 21;
    margin-bottom: -105px;
}

.teamWrapper > .titleWrapper > h1
{
    font-family: 'Playlist-script', regular;
    font-weight: 400;
    font-size: 60px;
    color: #034E60;
    z-index: 21;
}

.teamWrapper > .teamPeopleWrapper
{
    background-color: #B1D4E0;
    -webkit-clip-path: polygon(0 0, 100% 6%, 100% 94%, 0 100%);
    z-index: 0;
    padding-top: 3%;
    padding-bottom: 5%;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextRight
{
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 12%;
    padding-right: 12%;
    align-items: center;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextRight > .imageWrapper
{
    width: 200px;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextRight > .imageWrapper > center > img
{
    width: 200px;
    height: 200px;
    clip-path: circle(50%);
    margin: auto;
    padding: auto;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextRight > .separator
{
    width: 5%;
    height: 100%;
    background-image: url("../../ressources/images/full/teamSeparator.svg");
    background-size: 320px;
    background-repeat: no-repeat;
    background-position: -120px 125px;
    
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextRight > .textWrapper
{
    width: 75%;
    margin: auto;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextRight > .textWrapper > h1
{
    font-family: 'Playlist-script', regular;
    font-weight: 400;
    font-size: 40px;
    margin-bottom: 20px;
    color: #034e60;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextRight > .textWrapper > .paragraph > p
{
    text-align: justify;
    font-size: 20px;
    font-family: 'Raleway', regular;
    line-height: 1.5;
    font-weight: 400;
    color: #034e60;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft
{
    margin-top: -45px;
    margin-bottom: -20px;
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    padding-left: 12%;
    padding-right: 12%;
    align-items: center;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft > .imageWrapper
{
    width: 200px;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft > .imageWrapper > center > img
{
    width: 200px;
    height: 200px;
    clip-path: circle(50%);
    margin: auto;
    padding: auto;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft > .separator
{
    width: 5%;
    height: 100%;
    background-image: url("../../ressources/images/full/teamSeparator.svg");
    background-size: 320px;
    background-repeat: no-repeat;
    background-position: -120px 125px;
    transform: rotate(180deg);
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft > .textWrapper
{
    width: 75%;
    margin: auto;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft > .textWrapper > h1
{
    font-family: 'Playlist-script', regular;
    font-weight: 400;
    font-size: 40px;
    text-align: right;
    margin-bottom: 20px;
    color: #034e60;
}

.teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft > .textWrapper > .paragraph > p
{
    text-align: justify;
    font-size: 20px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #034e60;
    line-height: 1.5
}


@media screen and (max-width: 1500px) {
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextRight
    {
        margin-top: 110px;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 50px;
    }

    .teamWrapper > .teamPeopleWrapper > .personWrapperTextRight > .separator
    {
        visibility: hidden;
    }

    .teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft
    {
        margin-top: 150px;
        margin-bottom: 210px;
        flex-direction: column;
    }

    .teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft > .separator
    {
        visibility: hidden;
    }

    .teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft > .textWrapper > h1
    {
        text-align: left;
    }

    .teamWrapper > .teamPeopleWrapper
    {
        padding-top: 4%;
        padding-bottom: 10%;
    }

    .teamWrapper > .titleWrapper
    {
        margin-bottom: -125px;
    }
}

@media screen and (max-width: 1275px) {
    .teamWrapper > .teamPeopleWrapper
    {
        padding-top: 4%;
        padding-bottom: 15%;
    }
}

@media screen and (max-width: 1150px) {
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextRight
    {
        margin-top: 140px;
        margin-bottom: 50px;
    }
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft
    {
        margin-top: 180px;
        margin-bottom: 240px;
    }
    .teamWrapper > .teamPeopleWrapper
    {
        padding-top: 4%;
        padding-bottom: 18%;
    }
}

@media screen and (max-width: 950px) {
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextRight
    {
        margin-top: 190px;
        margin-bottom: 50px;
    }
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft
    {
        margin-top: 260px;
        margin-bottom: 320px;
    }
    .teamWrapper > .teamPeopleWrapper
    {
        padding-top: 4%;
        padding-bottom: 30%;
    }
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft > .textWrapper > h1
    {
        margin-top: 20px
    }
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextRight > .textWrapper > h1
    {
        margin-top: 20px
    }
    .teamWrapper > .titleWrapper
    {
        margin-bottom: -135px;
    }
}

@media screen and (max-width: 800px) {
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextRight
    {
        margin-top: 220px;
        margin-bottom: 50px;
        padding-left: 5%;
        padding-right: 5%;
    }
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft
    {
        margin-top: 280px;
        margin-bottom: 340px;
        padding-left: 5%;
        padding-right: 5%;
    }
    .teamWrapper > .teamPeopleWrapper
    {
        padding-top: 4%;
        padding-bottom: 40%;
    }
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft > .textWrapper > h1
    {
        margin-top: 20px
    }
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextRight > .textWrapper > h1
    {
        margin-top: 20px
    }
    .teamWrapper > .titleWrapper
    {
        margin-bottom: -135px;
    }
}

@media screen and (max-width: 650px) {
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextRight
    {
        margin-top: 220px;
        margin-bottom: 50px;
        padding-left: 0%;
        padding-right: 0%;
    }
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft
    {
        margin-top: 280px;
        margin-bottom: 380px;
        padding-left: 0%;
        padding-right: 0%;
    }
    .teamWrapper > .teamPeopleWrapper
    {
        padding-top: 4%;
        padding-bottom: 55%;
    }
}

@media screen and (max-width: 550px) {
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextRight
    {
        margin-top: 450px;
        margin-bottom: 10px;
        padding-left: 0%;
        padding-right: 0%;
    }
    .teamWrapper > .teamPeopleWrapper > .personWrapperTextLeft
    {
        margin-top: 480px;
        margin-bottom: 470px;
        padding-left: 0%;
        padding-right: 0%;
    }

    .teamWrapper > .teamPeopleWrapper
    {
        padding-top: 4%;
        padding-bottom: 1%;
    }

    .teamWrapper > .teamPeopleWrapper
    {
        -webkit-clip-path: polygon(0 0, 100% 1%, 100% 99%, 0 100%);
    }

    .teamWrapper > .titleWrapper > h1
    {
        font-size: 40px;
    }

    .teamWrapper > .titleWrapper
    {
        margin-bottom: -145px;
    }
}