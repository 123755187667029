.NavbarItems {
    position: fixed;
    top:0;
    width: 100%;
    background-color: rgb(58, 114, 87);
    height: 80px;
    display: flex;
    z-index: 10;
}

.navbar-logo {
    margin-left: 20px;
    margin-top: 8px;
    cursor: pointer;
}

.navbar-logo > a >img {
    height: 120px;
    -webkit-transition: transform 0.5s ease-in-out;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: right;
    width: 100%;
    margin-top: 30px;
    margin-right: 20px;
    justify-content: end;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 5px 15px;
}

.nav-links:hover {
    background-color: #D3DED8;
    color:#3A7257;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.dropdown {
    float: left;
    overflow: hidden;
    margin-top: -5px;
  }
  
  .dropdown .dropbtn {
    font-size: 17px;
    cursor: pointer;
    border: none;
    outline: none;
    color: white;
    padding: 5px;
    border-radius: 4px;
    background-color: inherit;
    font-family: inherit;
    margin: 0;
  }
  
  .dropbtn a {
    text-decoration: none;
    color: inherit
  }

  i {
      margin-left: 8px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .topnav a:hover, .dropdown:hover .dropbtn{
    background-color: #D3DED8;
    color: #3A7257;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
    color: black;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 750px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        grid-gap: 0px;
        top: 50px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: rgba(58, 114, 87, 0.959);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 25px;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transition: transform 0.5s ease-in-out;
        margin-left: 10px;
    }

    .navbar-logo > img {
        transform: scale(0.8);
        -webkit-transition: transform 0.5s ease-in-out;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        padding-right: 20px;
        padding-top: 23px;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #FFBD59;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
        z-index: 1;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: rgb(58, 114, 87);
        transition: 250ms;
    }

    .dropdown {
        float: none;
        padding: 1.5rem;
        align-self: center;
        text-align: center;
        padding: 0px;
        width: 100%;
        display: table;
        padding-top: 25px;
    }

    .dropdown-content {
        position: relative;
        text-align: center;
    }

    .dropdown-content a {
        float: none;
        color: black;
        padding: 12px 16px;
        text-align: center;
      }

    .dropdown .dropbtn {
        display: block;
        width: 100%;
        text-align: center;
        border-radius: 0px;
        padding: 25px 16px;
      }

    .dropdown:hover .nav-menu{
        height: 400px;
    }
}