@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.popup-content
{    
    width: 85%!important;
    height: 80%;
    padding: 5px;
    border-radius: 1rem;
}

.popup-content > .ModalContainerSingle
{
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
}

.ModalContainerSingle > .TitleWrapperSingle
{
    width: 45%;
    height: 100%;
    padding: 1%;
    overflow: hidden;
}

 .ModalContainerSingle > .TitleWrapperSingle > h1
{
    font-family: 'Dancing Script', regular;
    font-weight: 400;
    font-size: 50px;
    color: #000000;
    margin-bottom: 30px;
}

 .ModalContainerSingle > .TitleWrapperSingle > p
{
    text-align: justify;
    font-size: 20px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
    margin-bottom: 30px;
}

 .ModalContainerSingle > .TitleWrapperSingle > .CompanyPhotoWrapper
{
    width: 100%;
    display: none;
}

 .ModalContainerSingle > .TitleWrapperSingle > .CompanyPhotoWrapper > img
{
    width: 100%;
}

/* Single play company, only done for horizontal affiches, need to do for vertical too */
.ModalContainerSingle > .SinglePlayWrapper
{
    width: 50%;
    height: 100%;
    padding: 20px 25px;
}

.ModalContainerSingle > .SinglePlayWrapper > .playWrapperHSingle
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ModalContainerSingle > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH
{
    width: 100%;
    height: 400px;
}

.ModalContainerSingle > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH:hover
{
    transform: scale(1.05);
    transition: transform 330ms ease-out;
} 

.ModalContainerSingle > .SinglePlayWrapper  > .playWrapperHSingle > .playAfficheH > .imgContainerH
{
    transform: translate(-50%, -50%);
    visibility: hidden;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 150, 150, 0.5);
    position: relative;
    top: 50%;
    left: 50%;
}

.ModalContainerSingle > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH:hover > .imgContainerH
{
    visibility: visible;
}

.ModalContainerSingle > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH > .imgContainerH > img
{
    width: 40px;
    height: 40px;
}

.ModalContainerSingle > .SinglePlayWrapper > .playWrapperHSingle > p
{
    text-align: justify;
    font-size: 18px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
}
.ModalContainerSingle > .SinglePlayWrapper > .playWrapperHSingle > h1
{
    text-align: justify;
    font-size: 25px;
    font-family: 'Raleway', regular;
    font-weight: 700;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 30px;
}

@media screen and (max-width: 1650px) {
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH
    {
        width: 100%;
        height: 300px;
    }
}

@media screen and (max-width: 1300px) {
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH
    {
        width: 100%;
        height: 200px;
    }

    .ModalContainerSingle > .TitleWrapperSingle > h1
    {
        font-size: 40px;
        margin-bottom: 10px;
    }
    
     .ModalContainerSingle > .TitleWrapperSingle > p
    {
        text-align: justify;
        font-size: 18px;
        font-family: 'Raleway', regular;
        font-weight: 400;
        color: #000000;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 1000px) {
    .popup-content
    {    
        width: 85%!important;
        height: 95%!important;
        border-radius: 1rem;
    }
    .popup-content > .ModalContainerSingle
    {
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .ModalContainerSingle > .TitleWrapperSingle
    {
        width: 100%;
        height: 35%;
        overflow: hidden;
    }
    .ModalContainerSingle > .SinglePlayWrapper
    {
        width: 100%;
        height: 63%;
        padding: 0px;
    }
    .ModalContainerSingle > .TitleWrapperSingle > .CompanyPhotoWrapper
    {
        visibility: hidden;
    }
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > p
    {
        display: none;
    }

    .ModalContainerSingle > .SinglePlayWrapper > .playWrapperHSingle
    {
        justify-content: flex-end;
    }

    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH
    {
        width: 100%;
        height: 400px;
    }
    .ModalContainerSingle > .TitleWrapperSingle
    {
        overflow:visible;
    }
}

@media screen and (max-width: 850px) {
    .ModalContainerSingle > .TitleWrapperSingle
    {
        overflow:visible;
    }
    .ModalContainerSingle > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH
    {
        width: 100%;
        height: 300px;
    }

    .ModalContainerSingle > .SinglePlayWrapper > .playWrapperHSingle
    {
        justify-content: flex-end;
    }

    .ModalContainerSingle > .SinglePlayWrapper
    {

        display: flex;
        flex-direction: column;
        justify-content:flex-end;
        align-items: flex-end;
    }

}

@media screen and (max-width: 710px) {
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > h1 {
        text-align: justify;
        font-size: 25px;
    }
}

@media screen and (max-width: 750px) {
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH
    {
        width: 100%;
        height: 300px;
    }
    .ModalContainerSingle > .TitleWrapperSingle
    {
        overflow:visible;
    }
}

@media screen and (max-width: 672px) {
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH
    {
        width: 100%;
        height: 250px;
    }
    .ModalContainerSingle > .TitleWrapperSingle
    {
        overflow:visible;
    }
}

@media screen and (max-width: 623px) {
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH
    {
        width: 100%;
        height: 200px;
    }
    .ModalContainerSingle > .TitleWrapperSingle
    {
        height: 60%;
    }
    .ModalContainerSingle > .SinglePlayWrapper
    {
        height: 35%;
    }
    .ModalContainerSingle > .TitleWrapperSingle > h1
    {
        font-size: 35px;
        margin-bottom: 10px;
    }
    .ModalContainerSingle > .TitleWrapperSingle > p
    {
        font-size: 17px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 535px) {
    .ModalContainerSingle > .TitleWrapperSingle > h1
    {
        font-size: 30px;
        margin-bottom: 10px;
    }
    .ModalContainerSingle > .TitleWrapperSingle > p
    {
        font-size: 17px;
    }
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > h1 {
        text-align: justify;
        font-size: 20px;
    }
}

@media screen and (max-width: 499px) {
    .popup-content
    {    
        width: 85%!important;
        height: 95%;
        padding: 0px;
        border-radius: 1rem;
    }
    .ModalContainerSingle
    {
        padding: 0px;
    }
    .ModalContainerSingle > .TitleWrapperSingle > h1
    {
        font-size: 25px;
        margin-bottom: 10px;
    }
    .ModalContainerSingle > .TitleWrapperSingle > p
    {
        font-size: 15px;
    }
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > h1 {
        text-align: justify;
        font-size: 18px;
    }

    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH
    {
        width: 100%;
        height: 160px;
    }
    
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH > .imgContainerH
    {
        visibility: visible;
        background-color: rgba(150, 150, 150, 0);
    }

    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH:hover > .imgContainerH
    {
        background-color: rgba(150, 150, 150, 0.5);
    }

    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH > .imgContainerH > img
    {
        width: 30px;
        height: 30px;
    }
}

@media screen and (max-width: 500px) {
    .popup-content
    {    
        width: 85%!important;
        height: 95%;
        padding: 0px;
        border-radius: 1rem;
    }
    .ModalContainerSingle
    {
        padding: 0px;
    }
    .ModalContainerSingle > .TitleWrapperSingle > h1
    {
        font-size: 23px;
        margin-bottom: 10px;
    }
    .ModalContainerSingle > .TitleWrapperSingle > p
    {
        font-size: 14px;
    }
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > h1 {
        text-align: justify;
        font-size: 16px;
        margin-top: 10px;
    }

    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH
    {
        width: 100%;
        height: 130px;
    }
    
    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH > .imgContainerH
    {
        visibility: visible;
        background-color: rgba(150, 150, 150, 0);
    }

    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH:hover > .imgContainerH
    {
        background-color: rgba(150, 150, 150, 0.5);
    }

    .ModalContainerSingle > .SinglePlayWrapper >  .playWrapperHSingle > .playAfficheH > .imgContainerH > img
    {
        width: 30px;
        height: 30px;
    }
}
