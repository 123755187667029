@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.popup-content
{    
    width: 85%!important;
    height: 80%;
    padding: 5px;
    border-radius: 1rem;
}

.popup-content > .ModalContainer
{
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

 .ModalContainer > .TitleWrapper
{
    width: 45%;
    height: 100%;
    padding: 1%;
    overflow: hidden;
}

 .ModalContainer > .TitleWrapper > h1
{
    font-family: 'Dancing Script', regular;
    font-weight: 400;
    font-size: 50px;
    color: #000000;
    margin-bottom: 30px;
}

 .ModalContainer > .TitleWrapper > p
{
    text-align: justify;
    font-size: 16px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
    margin-bottom: 30px;
}

 .ModalContainer > .TitleWrapper > .CompanyPhotoWrapper
{
    width: 100%;
}

 .ModalContainer > .TitleWrapper > .CompanyPhotoWrapper > img
{
    width: 100%;
}

/* Two plays */

.ModalContainer > .twoPlaysWrapper
{
    width: 850px;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: auto;
    padding-right: auto;
    display: flex;
    flex-direction: row;
}

.ModalContainer > .twoPlaysWrapper > .playWrapper
{
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
}

.ModalContainer > .twoPlaysWrapper > .playWrapper > .playAffiche
{
    height: 450px;
}


.ModalContainer > .twoPlaysWrapper > .playWrapper > .playAffiche > .imgContainer
{
    transform: translate(-50%, -50%);
    visibility: hidden;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(150, 150, 150, 0.5);
    position: relative;
    top: 50%;
    left: 50%;
}

.ModalContainer > .twoPlaysWrapper > .playWrapper > .playAffiche:hover > .imgContainer
{
    visibility: visible;
}

.ModalContainer > .twoPlaysWrapper > .playWrapper > .playAffiche > .imgContainer > img
{
    width: 40px;
    height: 40px;
}

.ModalContainer > .twoPlaysWrapper > .playWrapper > p
{
    text-align: justify;
    font-size: 16px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
}
.ModalContainer > .twoPlaysWrapper > .playWrapper > h1
{
    text-align: justify;
    font-size: 20px;
    font-family: 'Raleway', regular;
    font-weight: 700;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 10px;
}

@media screen and (max-width: 1650px) {
    .ModalContainer > .twoPlaysWrapper
    {
        width: 650px;
        height: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

@media screen and (max-width: 1200px) {
    .popup-content > .ModalContainer
    {
        flex-direction: column;
    }
    .ModalContainer > .twoPlaysWrapper > .playWrapper > p
    {
        display: none;
    }
    .ModalContainer > .TitleWrapper > .CompanyPhotoWrapper
    {
        display: none;
    }
    .ModalContainer > .TitleWrapper
    {
        width: 100%;
        height: 40%;
        padding: 1%;
        overflow: visible;
    }

    .ModalContainer > .twoPlaysWrapper
    {
        width: 100%;
        height: 60%;
        padding-top: 55px;
        padding-bottom: 25px;
    }

}

@media screen and (max-width: 700px) {
    .ModalContainer > .twoPlaysWrapper
    {
        height: 40%;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .ModalContainer > .twoPlaysWrapper > .playWrapper
    {
        height: 100%;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .ModalContainer > .TitleWrapper
    {
        width: 100%;
        height: 60%;
        padding: 1%;
        overflow: visible;
    }
    .ModalContainer > .TitleWrapper > h1
    {
        font-size: 35px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 500px) {
    .ModalContainer > .twoPlaysWrapper
    {
        height: 20%;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .ModalContainer > .twoPlaysWrapper > .playWrapper
    {
        height: 100%;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 20px;
    }
    .ModalContainer > .TitleWrapper
    {
        width: 100%;
        height: 80%;
        padding: 0%;
        overflow: visible;
    }
    .ModalContainer > .TitleWrapper > h1
    {
        font-size: 25px;
        margin-bottom: 5px;
        margin-top: -10px;
    }
    .ModalContainer > .TitleWrapper > p
    {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .ModalContainer > .twoPlaysWrapper > .playWrapper > h1
    {
        font-size: 15px;
    }
}