@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face{
    font-family: 'Playlist-script';
    src:url('../../../fonts/PlaylistScript.ttf') format('truetype');
    font-style: normal, italic, oblique;
    font-weight: normal;
}
.companyDetailsWrapper
{    
    width: 100%!important;
    height: 650px;
    background-color: #B1D4E0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.companyDetailsWrapper > .firstColumnWrapper {
    width: 40%;
    padding: 25px 35px;
    overflow:hidden
}

.companyDetailsWrapper > .firstColumnWrapper > .companyNameWrapper{
    margin-bottom: 15px;
    height: 45px;
}

.companyDetailsWrapper > .firstColumnWrapper > .companyNameWrapper > h1{
    font-family: 'Playlist-script', regular;
    font-weight: 600;
    font-size: 40px;
    text-align: justify;
}

.companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper{
    margin-bottom: 15px;
    height: calc(70% - 45px);
}

.companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper > p{
    text-align: justify;
    font-size: 16px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
}
.companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper > p > p{
    text-align: justify;
    font-size: 18px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
}

.companyDetailsWrapper > .firstColumnWrapper > .CompanyPhotoWrapper {
    height: calc(30% - 45px);
    width: 100%;
    overflow: hidden;
    display: none;
}
.companyDetailsWrapper > .firstColumnWrapper > .CompanyPhotoWrapper > img {
    width: 100%;
}

.companyDetailsWrapper > .secondColumnWrapper {
    width: 60%!important;
    padding-right: 5px;
    padding-left: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
    overflow:hidden
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper {
    width: 100%;
    height: 100%;
    overflow:hidden
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    -ms-flex-align: center;
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH{
    height: calc(80% - 50px);
    width: 70%;
    align-items: center;
    overflow:hidden;
    margin-bottom: 25px;
    cursor: pointer;
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH:hover{
    transform: scale(1.05);
    transition: transform 330ms ease-out;
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH > .imageWrapper{
    transform: translate(-50%, -50%);
    visibility: hidden;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(3, 77, 96, 0.527);
    position: relative;
    top: 50%;
    left: 50%;
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH:hover > .imageWrapper
{
    visibility: visible;
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH > .imageWrapper > img
{
    width: 40px;
    height: 40px;
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheDescription
{
    height: calc(30% - 50px);
    width: 100%;
    overflow: hidden;
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheDescription > .titleWrapper{
    display: flex;
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheDescription > .titleWrapper > h1{
    text-align: justify;
    font-size: 25px;
    font-family: 'Raleway', regular;
    font-weight: 800;
    color: #000000;
    margin-bottom: 15px;
    margin-right: 15px;
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheDescription > .titleWrapper > a
{
    cursor: pointer;
}
.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheDescription > .titleWrapper > a > img
{
    margin-top: 2px;
    width: 25px;
    height: 25px;
}

.companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheDescription  > p{
    text-align: justify;
    font-size: 14px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
    line-height: 1.5;
}


.MultiplePlayWrapper {
    height: 100%;
    overflow:hidden
}

.MultiplePlayWrapper > .react-multi-carousel-list {
    height: 100%;
}

.MultiplePlayWrapper > .react-multi-carousel-list > .react-multi-carousel-track  {
    height: 100%;
}

.playWrapperColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    -ms-flex-align: center;
    padding: 0 10px;
}

.playWrapperColumn > .playAffiche {
    height: 400px;
    width: 280px;
    align-items: center;
    overflow:hidden;
    margin-bottom: 25px;
    cursor: pointer;
}

.playWrapperColumn > .playAffiche:hover{
    transform: scale(1.05);
    transition: transform 330ms ease-out;
}

.playWrapperColumn > .playAffiche > .imageWrapper{
    transform: translate(-50%, -50%);
    visibility: hidden;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(3, 77, 96, 0.527);
    position: relative;
    top: 50%;
    left: 50%;
}

.playWrapperColumn > .playAffiche:hover > .imageWrapper
{
    visibility: visible;
}

.playWrapperColumn > .playAffiche > .imageWrapper > img
{
    width: 40px;
    height: 40px;
}

.playWrapperColumn > .playAfficheDescription
{
    height: calc(40% - 50px);
    width: 100%;
    overflow: hidden;
}

.playWrapperColumn > .playAfficheDescription > .titleWrapper{
    display: flex;
}

.playWrapperColumn > .playAfficheDescription > .titleWrapper > h1{
    text-align: justify;
    font-size: 25px;
    font-family: 'Raleway', regular;
    font-weight: 800;
    color: #000000;
    margin-bottom: 5px;
    margin-right: 15px;
}

.playWrapperColumn > .playAfficheDescription > .titleWrapper > a
{
    cursor: pointer;
}
.playWrapperColumn > .playAfficheDescription > .titleWrapper > a > img
{
    margin-top: 2px;
    width: 25px;
    height: 25px;
}

.playWrapperColumn > .playAfficheDescription  > p{
    text-align: justify;
    font-size: 14px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
    line-height: 1.5;
}

.companyDetailsWrapper > .close {
    position: relative;
    right: 30px;
    top: -15px;
    width: 25px;
    height: 25px;
    opacity: 0.6;
    z-index: 1;
}
.companyDetailsWrapper > .close:hover {
    opacity: 1;
}
.companyDetailsWrapper > .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
}
.companyDetailsWrapper > .close:before {
    transform: rotate(45deg);
}
.companyDetailsWrapper > .close:after {
    transform: rotate(-45deg);
}

.companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper > p > p {
    line-height: 1.5;
}

@media screen and (max-width: 1875px) {
    .companyDetailsWrapper > .firstColumnWrapper > .companyNameWrapper {
        margin-bottom: 15px;
        height: auto;
    }
    
    .companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper > p > p {
        font-size: 14px !important;
    }

    .playWrapperColumn > .playAfficheDescription  > p{
        font-size: 14px;
    }

    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheDescription > .titleWrapper > h1{
        font-size: 22px;
        margin-bottom: 15px;
        margin-right: 10px;
    }

    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheDescription  > p{
        font-size: 14px!important;
    }

    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH {
        width: 80%;
    }
}

@media screen and (max-width: 1550px) {

    .companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper > p > p {
        font-size: 13px !important;
    }

    .playWrapperColumn > .playAfficheDescription  > p{
        font-size: 13px;
    }

    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheDescription  > p{
        font-size: 13px!important;
    }

    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH {
        height: calc(80% - 50px);
        width: 90%;
    }
}

@media screen and (max-width: 1400px) {

    .companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper{
        height: calc(100% - 45px);
    }

    .companyDetailsWrapper > .firstColumnWrapper > .CompanyPhotoWrapper {
        height: calc(0% - 45px);
        width: 100%;
        overflow: hidden;
    }
}

@media screen and (max-width: 1250px) {
    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH {
        height: calc(80% - 50px);
        width: 100%;
    }
    .playWrapperColumn > .playAfficheDescription  > p{
        font-size: 12px;
    }
}

@media screen and (max-width: 1150px) {
    .playWrapperColumn > .playAfficheDescription  > p{
        font-size: 11px;
    }
}

@media screen and (max-width: 1050px) {

    .companyDetailsWrapper > .firstColumnWrapper {
        width: 100%;
        overflow: visible;
        padding-bottom: 5px;
        padding-top: 55px;
    }

    .companyDetailsWrapper > .secondColumnWrapper {
        width: 90%!important;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        margin: auto;
        overflow: visible;
    }

    .companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper > p > p {
        font-size: 14px!important;
    }

    .playWrapperColumn > .playAfficheDescription  > p{
        font-size: 14px!important;
    }
    
    .companyDetailsWrapper
    {    
        width: 100%!important;
        height: 100%!important;
        flex-direction: column;
        background-color: #B1D4E0;
        display: flex;
        justify-content:flex-start;
        position:fixed;
        padding:0;
        margin:0;
        top:0;
        left:0;
        z-index: 1000;
        overflow-x: hidden;
        overflow-y: scroll!important;
    }

    .companyDetailsWrapper > .firstColumnWrapper > .CompanyPhotoWrapper {
        height: 0;
        width: 100%;
        overflow: hidden;
    }
    .companyDetailsWrapper > .firstColumnWrapper > .CompanyPhotoWrapper > img {
        width: 100%;
    }

    .MultiplePlayWrapper {
        overflow-y:scroll!important;
    }

    .companyDetailsWrapper > .close {
        position: fixed;
        right: 30px;
        top: 15px;
        width: 25px;
        height: 25px;
        z-index: 150;
    }

    .companyDetailsWrapper > .commandsBar {
        width: 100%;
        height: 60px;
        background-color:#B1D4E0;;
        z-index: 100;
        position: fixed;
        top: 0px;
        left: 0px;
    }

    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper {
        width: 100%;
        height: fit-content;
        overflow: visible;
        min-height: 650px;
    }

    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle {
        overflow-y: visible;
    }

    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheDescription
    {
        height: fit-content;
        width: 100%;
        overflow-y: visible;
    }

    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH {
        height: 450px;
        width: 850px;
    }

    .companyDetailsWrapper > .firstColumnWrapper > .companyNameWrapper {
        margin-bottom: 15px;
        height: auto;
    }
}


@media screen and (max-width: 910px) {
    .companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper > p > p {
        font-size: 15px!important;
    }

    .playWrapperColumn > .playAfficheDescription  > p{
        font-size: 15px!important;
    }
    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH {
        height: 350px;
        width: 650px;
    }
}

@media screen and (max-width: 840px) {
    .companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper > p > p {
        font-size: 14px!important;
    }

    .playWrapperColumn > .playAfficheDescription  > p{
        font-size: 14px!important;
    }
    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper {
        min-height: 600px;
    }
}

@media screen and (max-width: 715px) {
    .companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper > p > p {
        font-size: 13px!important;
    }

    .playWrapperColumn > .playAfficheDescription  > p{
        font-size: 13px!important;
    }
    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH {
        height: 275px;
        width: 550px;
    }
}

@media screen and (max-width: 610px) {
    .companyDetailsWrapper > .firstColumnWrapper > .companyDescriptionWrapper > p > p {
        font-size: 13px!important;
    }

    .playWrapperColumn > .playAfficheDescription  > p{
        font-size: 13px!important;
    }
    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH {
        height: 225px;
        width: 450px;
    }
}

@media screen and (max-width: 500px) {

    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH {
        height: 200px;
        width: 400px;
    }
    .companyDetailsWrapper > .firstColumnWrapper > .companyNameWrapper {
        margin-bottom: 15px;
        height: auto;
    }
}
@media screen and (max-width: 425px) {

    .companyDetailsWrapper > .secondColumnWrapper > .SinglePlayWrapper > .playWrapperHSingle > .playAfficheH {
        height: 175px;
        width: 350px;
    }
}