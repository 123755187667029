@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.pieceDetails
{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pieceDetails 
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
}

.pieceDetails  > .TopBannerWrapper
{
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.pieceDetails  > .TopBannerWrapper > .TitleWrapper
{
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100px;
}

.pieceDetails  > .TopBannerWrapper > .TitleWrapper  > h1
{
    margin-bottom: 15px;
    font-family: 'Dancing Script', regular;
    font-weight: 700;
    font-size: 40px;
    color: #000000;
    margin-bottom: 15px;
}

.pieceDetails  > .PlayInfoBanner
{
    margin-top: 40px;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;
}

.pieceDetails  > .PlayInfoBanner > .PlayDescription 
{
    width: 45%;
}

.pieceDetails  > .PlayInfoBanner > .PlayDescription  > h1
{
    text-align: justify;
    font-size: 40px;
    font-family: 'Raleway', regular;
    font-weight: 800;
    color: #000000;
    margin-bottom: 10px;
}

.pieceDetails  > .PlayInfoBanner > .PlayDescription  > h2
{
    text-align: justify;
    font-size: 18px;
    font-family: 'Raleway', regular;
    font-weight: 500;
    color: #000000;
    margin-bottom: 15px;
}

.pieceDetails  > .PlayInfoBanner > .PlayDescription  > .descriptionDetailedPlay
{
    margin-bottom: 32px;
}

.pieceDetails  > .PlayInfoBanner > .PlayDescription  > .descriptionDetailedPlay > p
{
    text-align: justify;
    font-size: 18px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
    margin-bottom: 8px;
}

.pieceDetails  > .PlayInfoBanner > .PlayDescription > a
{
    display: block;
    text-decoration: none!important;
    color: #EBF6FC;
    text-align: center;
    font-family: 'Raleway', regular;
    font-weight: 400;
    text-transform:capitalize;
    font-size: 22px;
    border-radius: 6px;
    padding: 15px 0px;
    width: 90%;
    cursor: pointer;
    border: none;
    background-color:#0C6980;
    outline: 0;
    cursor: pointer;
    margin: auto;
}

.pieceDetails  > .PlayInfoBanner > .PlayDescription  >  a:hover {
    transition-duration: 0.1s;
    background-color: #B1D4E0;
    color: #000000;
}

.pieceDetails  > .PlayInfoBanner > .PlayVisuals {
    width: 55%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 20px;
}

.pieceDetails  > .PlayInfoBanner > .PlayVisuals > .TrailerContainer {
    width: 100%;
    height: 100%;
}

.pieceDetails  > .PlayInfoBanner > .PlayVisuals > .TrailerContainer > .iframe {
    width: 100%;
    height: 100%;
}

.pieceDetails  > .PlayInfoBanner > .PlayVisuals > .TrailerPlaceHolderContainer {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1250px) {
    .pieceDetails  > .PlayInfoBanner{
        flex-direction: column;
        justify-content: center;
    }

    .pieceDetails  > .PlayInfoBanner > .PlayDescription 
    {
        width: 100%;
    }

    .pieceDetails  > .PlayInfoBanner > .PlayVisuals {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 0px;
        padding-right: 0px;
        height:700px;
    }

    .pieceDetails  > .TopBannerWrapper > .TitleWrapper
    {
        width: 80%;
        height: 100px;
    }
    .pieceDetails  > .TopBannerWrapper > .TitleWrapper  > h1
    {
        font-size: 35px;
    }
}

@media screen and (max-width: 900px) {
    .pieceDetails  > .PlayInfoBanner > .PlayVisuals {
        height:500px;
    }
}

@media screen and (max-width: 750px) {
    .pieceDetails  > .PlayInfoBanner > .PlayVisuals {
        height:400px;
    }
    .pieceDetails  > .PlayInfoBanner
    {
        padding-left: 40px;
        padding-right: 40px;
    }
    .pieceDetails > .PlayInfoBanner > .PlayDescription > a {
        font-size: 18px;
    }

    .pieceDetails  > .TopBannerWrapper > .TitleWrapper
    {
        width: 85%;
        height: 100px;
    }
    .pieceDetails  > .TopBannerWrapper > .TitleWrapper  > h1
    {
        font-size: 30px;
    }
    .pieceDetails  > .PlayInfoBanner > .PlayDescription  > h1
    {
        font-size: 25px;
    }
    .pieceDetails  > .PlayInfoBanner > .PlayDescription  > h2
    {
        font-size: 18px;
    }
    .pieceDetails  > .PlayInfoBanner > .PlayDescription  > p
    {
        font-size: 18px;
    }
}

@media screen and (max-width: 500px) {
    .pieceDetails  > .PlayInfoBanner > .PlayVisuals {
        height:300px;
    }
    .pieceDetails  > .TopBannerWrapper > .TitleWrapper  > h1
    {
        text-align: center;
    }
    .pieceDetails  > .TopBannerWrapper > .TitleWrapper
    {
        height: 150px;
    }
}