.EntryWrapper {
    position: relative;
    background-image: url("../../ressources/images/full/header_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    display: flex;
    padding: 3em 0 0 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ContainerEntry {
    width: 70%;
    height: 88vh;
    min-height: 550px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    clip-path: polygon(0 0, 100% 0, 100% 82%, 0 93%);
}

.EntryLogo {
    margin-bottom: 1vw;
    width: 90%;
    height: 90%;
}

.EntryTitle {
    font-family: unset;
    font-family: 'Montserrat', sans-serif, bold;
    font-size: 120px;
    font-weight: 600;
}

.EntryTitle {
    color:#3A7257
}

.EntrySubTitle {
    font-family: unset;
    font-family: 'Lora', serif, italic; 
    font-size: 30px;
    color: #52575c;
    text-align: center;
}

.ArrowDown {
    height: 100px;
    margin-top: -35px;
    cursor: pointer;
}

@media screen and (max-width: 1650px) {
    .ContainerEntry {
        width: 90%;
    }
    .ArrowDown {
        margin-top: -35px;
    }
}

@media screen and (max-width: 1150px) {
    .ContainerEntry {
        width: 95%;
    }
    .ArrowDown {
        margin-top: -80px;
    }
}

@media screen and (max-width: 900px) {
    .ContainerEntry {
        height: 50vh;
        min-height: 350px;
    }
    .ArrowDown {
        height: 60px;
    }
}

@media screen and (max-width: 700px) {
    .ContainerEntry {
        height: 400px;
        min-height: 350px;
    }
    .EntryWrapper {
        padding-top: 1em;
    }
    .ArrowDown {
        height: 60px;
    }
    .EntryLogo {
        height: 250px;
        width: 475px;
    }
}

@media screen and (max-width: 500px) {
    .ContainerEntry {
        height: 400px;
        min-height: 350px;
    }
    .EntryWrapper {
        padding-top: 1em;
    }
    .ArrowDown {
        height: 60px;
    }
    .EntryLogo {
        height: 187px;
        width: 356px;
    }
}