@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.playDetailsWrapper
{    
    width: 100%!important;
    height: 850px;
    background-color: #B1D4E0;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.playDetailsWrapper > .playTopWrapper {
    width: 100%;
    height: 45%;
    min-height: 276px;
    padding: 25px 40px;
    overflow: hidden;
}

.playDetailsWrapper > .playTopWrapper > h1{
    text-align: justify;
    font-size: 32px;
    font-family: 'Raleway', regular;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
}

.playDetailsWrapper > .playTopWrapper > p{
    text-align: justify;
    font-size: 18px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
    margin-bottom: 5px;
}

.playDetailsWrapper > .playTopWrapper > p > p{
    text-align: justify;
    font-size: 18px;
    line-height : 25px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
    margin-bottom: 5px;
}


.playDetailsWrapper > .playBottomWrapper {
    width: 100%;
    height: 55%;
    display: flex;
    flex-direction: row;
    max-height: 330px;
    justify-content: left;
}

.playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper {
    width: 48%;
    height: 100%;
    padding-left: 30px;
    padding-right: 25px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
}

.playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > h1 {
    text-align: justify;
    font-size: 25px;
    font-family: 'Raleway', regular;
    font-weight: 700;
    color: #000000;
    margin-bottom: -5px;
}

.playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > ul {
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 25px;
}

.playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > ul > li {
    text-align: justify;
    margin-bottom: 5px;
    font-size: 18px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
    margin-bottom: 5px;
}
.playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > ul > li > b{
    font-family: 'Raleway', regular;
}
.playDetailsWrapper > .playBottomWrapper > .playBottomSecondColumnWrapper {
    width: 50%;
    height: 100%;
}

.playDetailsWrapper > .playBottomWrapper > .playBottomSecondColumnWrapper > .TrailerPlaceHolderContainer{
    width: 600px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.playDetailsWrapper > .playBottomWrapper > .playBottomSecondColumnWrapper > .TrailerContainer{
    width: 600px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}


.playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > .buttonDownloadDossier
{
    display: block;
    text-decoration: none!important;
    color: #EBF6FC;
    text-align: center;
    font-family: 'Raleway', regular;
    font-weight: 400;
    text-transform:capitalize;
    font-size: 22px;
    border-radius: 6px;
    padding: 15px 0px;
    width: 90%;
    cursor: pointer;
    border: none;
    background-color:#0C6980;
    outline: 0;
    cursor: pointer;
    margin: auto;
    align-self: flex-end;
    margin-bottom: 0px;
}

.playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper >  .buttonDownloadDossier:hover {
    transition-duration: 0.1s;
    background-color: #1686a1;
    color: #EBF6FC;
}

.playDetailsWrapper > .close {
    position: relative;
    left: calc(100% - 30px - 25px);
    right:30px;
    top: calc(-100% + 75px - 0px - 0px);
    width: 25px;
    height: 25px;
    opacity: 0.6;
    z-index: 1;
}
.playDetailsWrapper > .close:hover {
    opacity: 1;
}
.playDetailsWrapper > .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
}
.playDetailsWrapper > .close:before {
    transform: rotate(45deg);
}
.playDetailsWrapper > .close:after {
    transform: rotate(-45deg);
}

.playDetailsWrapper > .arrow {
    position: relative;
    width: 20px;
    height: 20px;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
    opacity: 0.6;
    top: calc(-100% + 101px - 0px);
    left: calc(100% - 30px - 55px);
}

.arrow-left {
    transform: rotate(-135deg);
}

.playDetailsWrapper > .arrow:hover {
    opacity: 1;
}


@media screen and (max-width: 1750px) {
    .playDetailsWrapper > .playTopWrapper > h1{
        font-size: 30px;
        margin-bottom: 8px;
    }
    
    .playDetailsWrapper > .playTopWrapper > p > p{
        font-size: 16px!important;
        line-height : 25px;
        margin-bottom: 5px;
    }

    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > h1 {
        font-size: 23px;
        margin-bottom: -7px;
    }
    
    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > ul {
        margin-top: 15px;
        margin-bottom: 0px;
    }
    
    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > ul > li {
        margin-bottom: 5px;
        font-size: 16px!important;
    }

    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > .buttonDownloadDossier
    {
        font-size: 20px;
    }
    
}

@media screen and (max-width: 1300px) {

    .playDetailsWrapper > .playTopWrapper > h1{
        font-size: 28px;
        margin-bottom: 7px;
    }
    
    .playDetailsWrapper > .playTopWrapper > p > p{
        font-size: 15px!important;
        line-height : 25px;
        margin-bottom: 5px;
    }

    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > h1 {
        font-size: 20px;
        margin-bottom: -7px;
    }
    
    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > ul > li {
        margin-bottom: 5px;
        font-size: 15px!important;
    }

    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > .buttonDownloadDossier
    {
        font-size: 18px;
        padding: 7px 0px;
        margin-top: 10px;
    }

    .playDetailsWrapper > .playTopWrapper {
        width: 100%;
        height: 55%;
        padding: 25px 40px;
        overflow: hidden;
    }

    .playDetailsWrapper > .playBottomWrapper {
        width: 100%;
        height: 45%;
        display: flex;
        flex-direction: row;
        max-height: 330px;
        justify-content: left;
    }

    .playDetailsWrapper > .playBottomWrapper > .playBottomSecondColumnWrapper > .TrailerContainer {
        width: 450px;
    }
}

@media screen and (max-width: 1050px) {
    .playDetailsWrapper
    {    
        width: 100%!important;
        height: 100%!important;
        background-color: #B1D4E0;
        display: inline-block;
        flex-direction: column;
        justify-content: flex-start;
        position:fixed;
        padding:0;
        margin:0;
        top:0;
        left:0;
        z-index: 1000;
        overflow-x: hidden;
        overflow-y: scroll!important;
        padding-top: 30px;
    }

    .playDetailsWrapper > .playTopWrapper > p > p{
        font-size: 14px!important;
        margin-bottom: 5px;
        line-height : 25px;
    }

    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > ul > li {
        margin-bottom: 5px;
        font-size: 14px!important;
    }

    .playDetailsWrapper > .playTopWrapper {
        width: 100%;
        height: fit-content;
        padding: 25px 40px;
        overflow: visible;
    }

    .playDetailsWrapper > .playBottomWrapper {
        height: auto;
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

    .playDetailsWrapper > .playBottomWrapper > .playBottomSecondColumnWrapper {
        width: 100%;
        height: fit-content;
    }

    .playDetailsWrapper > .playBottomWrapper > .playBottomSecondColumnWrapper > .TrailerContainer {
        width: 800px!important;
        height: 450px;
        margin-bottom: 30px;
    }

    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper {
        width: 100%;
        height: fit-content;
        margin-left: 0px;
        padding-left: 40px;
        padding-right: 40px;
        margin-bottom: 10px!important;
    }
    
    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > .buttonDownloadDossier
    {
        font-size: 18px;
        padding: 7px 0px;
        margin-top: 20px;
        margin-bottom: 30px;
        width: 50%;
        min-width: 375px;
    }

    .playDetailsWrapper > .close {
        position: fixed;
        right: 30px;
        top: 15px;
        width: 25px;
        height: 25px;
        z-index: 150;
    }

    .playDetailsWrapper > .arrow {
        position: fixed;
        z-index: 150;
        width: 20px;
        height: 20px;
        border-top: 2px solid #333;
        border-right: 2px solid #333;
        top: 21px;
        right: 45px;
    }

    .playDetailsWrapper > .commandsBar {
        width: 100%;
        height: 60px;
        background-color:#B1D4E0;;
        z-index: 100;
        position: fixed;
        top: 0px;
        left: 0px;
    }

}

@media screen and (max-width: 900px) {
    .playDetailsWrapper > .playBottomWrapper > .playBottomSecondColumnWrapper > .TrailerContainer {
        width: 600px!important;
        height: 350px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 675px) {
    .playDetailsWrapper > .playBottomWrapper > .playBottomSecondColumnWrapper > .TrailerContainer {
        width: 500px!important;
        height: 250px;
        margin-bottom: 30px;
    }
}


@media screen and (max-width: 575px) {
    .playDetailsWrapper > .playBottomWrapper > .playBottomSecondColumnWrapper > .TrailerContainer {
        width: 400px!important;
        height: 230px;
        margin-bottom: 20px;
    }

    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > .buttonDownloadDossier
    {
        font-size: 16px;
        min-width: 300px;
    }
}

@media screen and (max-width: 425px) {
    .playDetailsWrapper > .playBottomWrapper > .playBottomSecondColumnWrapper > .TrailerContainer {
        width: 345px!important;
        height: 190px;
        margin-bottom: 20px;
    }

    .playDetailsWrapper > .playBottomWrapper > .playBottomFirstColumnWrapper > .buttonDownloadDossier
    {
        font-size: 16px;
        min-width: 300px;
    }
}