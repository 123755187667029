@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face{
    font-family: 'Playlist-script';
    src:url('../../fonts/PlaylistScript.ttf') format('truetype');
    font-style: normal, italic, oblique;
    font-weight: normal;
}

.aboutUsWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.aboutUsWrapper > .textWrapper{
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.aboutUsWrapper > .textWrapper > .titleWrapper{
    margin-top: 2%;    
    width: 100%;
    background-image: url("../../ressources/images/full/aboutUsUnderline.svg");
    background-size: 320px;
    background-repeat: no-repeat;
    background-position: 0 -30px;
    height: 120px;
}

.aboutUsWrapper > .textWrapper > .titleWrapper > h1{
    font-family: 'Playlist-script', regular;
    font-weight: 400;
    font-size: 50px;
    color: #034E60;
}

.aboutUsWrapper > .textWrapper > p{
    text-align: justify;
    font-size: 22px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #034E60;
    line-height: 1.5;
}

@media screen and (max-width: 500px) {
    .aboutUsWrapper > .textWrapper > p{
        text-align: justify;
        font-size: 18px;
        font-family: 'Raleway', regular;
        font-weight: 400;
        color: #000000;
    }
}