@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face{
    font-family: 'Playlist-script';
    src:url('../../fonts/PlaylistScript.ttf') format('truetype');
    font-style: normal, italic, oblique;
    font-weight: normal;
}

.FooterWithContactWrapper{
    background-color: #B1D4E0;
    position: relative;
    padding: 60px 250px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.FooterWithContactWrapper > .TitleWrapper {
    width: 90%;
    margin-top: -100px;
}

.FooterWithContactWrapper >.TitleWrapper > h2 {
    font-family: 'Playlist-script', regular;
    font-weight: 400;
    font-size: 60px;
    color: #034E60;
    z-index: 21;
    margin-bottom: 50px;
    text-align: center;
}

.FooterWithContactWrapper > .ContactFormContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: center;
    text-align: center;
    justify-content: center;
}

.ContactFormContainer > .ContactForm {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 0px 30px 80px 50px;
}

.ContactForm > .FirstLine {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
}

.ContactForm > .FirstLine > input{
    margin: 0 5px;
    display: block;
    background: #B1D4E0;
    border: solid 1px #0C6980;
    border-radius: 0.35em;
    width: 100%;
    line-height: 1.5em;
    padding: 0.75em;
    font-weight: 300;
    color: #0C6980;
    cursor: text;
}

.ContactForm > .FirstLine > input::placeholder {
    color: #61A3BB;
}

.ContactForm > .MessageContents {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ContactForm > .MessageContents > textarea{
    margin: 0 5px;
    display: block;
    background: #B1D4E0;
    border: solid 1px #0C6980;
    border-radius: 0.35em;
    width: calc(100% - 10px);
    line-height: 1.5em;
    padding: 0.75em;
    font-weight: 300;
    color: #0C6980;
    cursor: text;
    min-height: 150px;
    resize: none;
}

.ContactForm > .MessageContents > textarea::placeholder {
    color: #61A3BB;
}

.ContactForm > .MessageContents > .SubmitFiles{
    margin: 10px 5px;
    text-align: start;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.ContactForm > .MessageContents > .SubmitFiles > input{
    display: block;
    background: #f9fbfe;
    border: solid 1px #d2d7dc;
    border-radius: 0.35em;
    width: calc(100% - 10px - 180px - 5px);
    line-height: 1.5em;
    padding: 18px;
    font-weight: 300;
    color: #52575c;
    cursor: text;
}

.ContactForm > .MessageContents > .SubmitFiles > button {
    text-decoration: none!important;
    color: #EBF6FC;;
    text-align: center;
    text-transform:capitalize;
    font-size: 22px;
    width: 180px;
    border-radius: 6px;
    margin-right: 10px;
    padding: 15px 0px;
    cursor: pointer;
    border: none;
    background-color:#0C6980;
    outline: 0;
}

.ContactForm > .MessageContents > .SubmitFiles > button:hover {
    transition-duration: 0.1s;
    background-color: #EBF6FC;
    color: #0C6980;
}

.ContactFormContainer > .Contacts {
    width: 40%;
    padding: 0px 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ContactFormContainer > .Contacts > .elementContactMail{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 20px 40px;
    align-items: center;
}

.ContactFormContainer> .Contacts > .elementContactRedes{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.elementContactMail > a{
    font-size: 18px;
    margin: 0px 8px;
    color: #0C6980;
    text-decoration: none;
    font-weight: 400;
}
/*.elementContactMail >img{
    width: 80px;
}*/
.elementContactMail > a >img{
    width: 80px;
    margin: 0px 0px;

}
.elementContactRedes > a >img{
    width: 80px;
    margin: 10px 20px;
}
.elementContactRedes > a >img:hover{
    width: 85px;
}
.elementContactMail > a >img:hover{
    width: 85px;
}
.elementContactMail > a:hover{
    color:#ffffff;
}

.FooterWithContactWrapper > .CopyrightContainer{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    border-top: solid 1px #0C6980;;
    padding-top: 10px;
    padding-bottom: 40px;
    color: #0C6980;;
}
 
.FooterWithContactWrapper > .InfoJunta{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: center;
    text-align: justify;
    justify-content: center;
}

.InfoJunta > .InfoJuntaImagen{
    width: 10%;
    padding: 00px 50px;
}

.InfoJuntaImagen >img{
    width: 300px;
    height: 80px;   
}

.InfoJunta > .InfoJuntaTexto{
    width: 90%;
    padding: 15px 0px 0px 230px;
    color: #0C6980;
    font-size: 12px;
}

.FooterWithContactWrapper > .InfoSubvencion{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: center;
    text-align: justify;
    justify-content: center;
}

.InfoSubvencion > .InfoSubvencionUEImagen{
    width: 10%;
    padding: 20px 50px;
}

.InfoSubvencionUEImagen >img{
    width: 480px;
    height: 70x;   
}

.InfoSubvencion > .InfoSubvencionTexto{
    width: 90%;
    padding: 20px 10px 0px 450px;
    color: #0C6980;
    font-size: 12px;
}

@media screen and (max-width: 1825px) {
    .FooterWithContactWrapper{
        padding: 60px 100px;
    }
}

@media screen and (max-width: 1725px) {
    .FooterWithContactWrapper{
        padding: 60px 150px;
    }
}

@media screen and (max-width: 1625px) {
    .FooterWithContactWrapper{
        padding: 60px 100px;
    }
}

@media screen and (max-width: 1525px) {
    .FooterWithContactWrapper{
        padding: 60px 50px;
    }
}

/*@media screen and (max-width: 1425px) {
    .ContactFormContainer > .Contacts {
        width: 30%;
        padding: 30px 0px;
    }
}*/

@media screen and (max-width: 1200px) {
    .FooterWithContactWrapper > .ContactFormContainer{
        flex-direction: column;
        justify-content: center;
    }
    .ContactFormContainer > .Contacts {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        width: 100%;
        flex-direction: row;
    }

    .ContactFormContainer > .ContactForm {
        width: 100%;
        padding: 0px 50px;
    } 

    .FooterWithContactWrapper > .InfoJunta{
        flex-direction: column;
        justify-content: center;
    }
    .InfoJunta > .InfoJuntaImagen {
        padding: 20px 10px;
    }
    .InfoJunta > .InfoJuntaTexto {
        padding: 0px 10px;
        margin-left: 0px;
    }
    .FooterWithContactWrapper > .InfoSubvencion{
        flex-direction: column;
        justify-content: center;
    }
    .InfoSubvencion > .InfoSubvencionUEImagen {
        padding: 10px 10px;
    }
    .InfoSubvencion > .InfoSubvencionTexto {
        padding: 10px 10px;
        margin-left: 0px;
    }
}

@media screen and (max-width: 740px) {
    .FooterWithContactWrapper > .ContactFormContainer{
        flex-direction: column;
        justify-content: center;
    }
    .ContactFormContainer > .ContactForm {
        width: 100%;
        padding: 0px 0px;
    }
    .ContactFormContainer > .Contacts{
        flex-direction: column;
        justify-content: center;
        padding: 0px 20px;
    }

}
@media screen and (max-width: 550px) {
    .FooterWithContactWrapper > .ContactFormContainer{
        flex-direction: column;
        justify-content: center;
    }
    .ContactFormContainer > .Contacts{
        flex-direction: column;
        justify-content: center;
        padding: 0px 20px;
    }
    .ContactFormContainer > .Contacts > .elementContactRedes {
        flex-direction: column;
        justify-content: left;
        margin-left: 0px;
        padding-left: 0px;
    }

    .ContactForm > .MessageContents > .SubmitFiles{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .ContactForm > .MessageContents > .SubmitFiles > input{
        width: calc(100% - 10px);
    }
    .ContactForm > .MessageContents > .SubmitFiles > button {
        width: calc(100% - 10px);
        margin-top: 10px;
    }
    .FooterWithContactWrapper >.TitleWrapper > h2 {
        font-size: 40px;
        margin-bottom: 30px;
        text-align: center;
    }

    .FooterWithContactWrapper > .TitleWrapper {
        margin-top: -90px;
    }
}