@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face{
    font-family: 'Playlist-script';
    src:url('../../fonts/PlaylistScript.ttf') format('truetype');
    font-style: normal, italic, oblique;
    font-weight: normal;
}

.ourCompaniesWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    /* background-image: url("../../ressources/images/full/header_background.png");
    background-size: cover;
    background-repeat: no-repeat; */
}

.ourCompaniesWrapper > .titleWrapper{
    width: 25%;
    margin: auto;
    padding-top: 2%;
}

.ourCompaniesWrapper > .titleWrapper > h1{
    font-family: 'Playlist-script', regular;
    font-weight: 400;
    font-size: 60px;
    color: #034E60;
    text-align: center;
}

.ourCompaniesWrapper > .adultCompaniesWrapper
{
    margin-top: 0%;    
    width: 100%;
    background-image: url("../../ressources/images/full/adultsBackgound.svg");
    background-size: 1900px;
    background-repeat: no-repeat;
    background-position: 0 -30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 110px;
}

.ourCompaniesWrapper > .adultCompaniesWrapper > .adultsTitleWrapper
{
    margin-top: 140px;
    margin-left: 220px;
    margin-bottom: 80px;
}

.ourCompaniesWrapper > .adultCompaniesWrapper > .adultsTitleWrapper > h1
{
    font-family: 'Playlist-script', regular;
    font-weight: 700;
    font-size: 60px;
    color: #803731;
    text-align: left;
}

.ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper
{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    margin-top: 20px;
    margin-left: 250px;
    margin-bottom: 350px;
}

.ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper > .companyWrapper
{
    height: 300px;
    width: 300px;
    margin: auto;
    z-index: 41;
    cursor: pointer;
    transition: transform 330ms ease-out;
}

.ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper > .companyWrapper > h1
{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(219, 76, 64, 0.5);
    font-family: 'Playlist-script', regular;
    font-weight: 700;
    font-size: 45px;
    color: #ffffff;
}

.ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper > .companyWrapper:hover
{
    transform: scale(1.1);
    transition: transform 330ms ease-out;
}

.ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper > .companyWrapper:hover > h1
{
    visibility: visible;
}

.ourCompaniesWrapper > .familiarCompaniesWrapper
{
    margin-top: -190px;    
    width: 100%;
    background-image: url("../../ressources/images/full/familiarBackground.svg");
    background-size: 1700px;
    background-repeat: no-repeat;
    background-position: 0 0px;
    display: flex;
    flex-direction: column;
    padding-bottom: 150px;
    justify-content: center;
}

.ourCompaniesWrapper > .familiarCompaniesWrapper > .familiarTitleWrapper
{
    margin-top: 140px;
    margin-right: 320px;
    margin-bottom: 80px;
}

.ourCompaniesWrapper > .familiarCompaniesWrapper > .familiarTitleWrapper > h1
{
    font-family: 'Playlist-script', regular;
    font-weight: 700;
    font-size: 60px;
    color: #034E60;
    text-align: right;
}

.ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper
{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    margin-top: 20px;
    margin-left: 250px;
    margin-bottom: 190px;
}

.ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper > .companyWrapper
{
    height: 350px;
    width: 350px;
    margin: auto;
    z-index: 41;
    cursor: pointer;
    transition: transform 330ms ease-out;
}

.ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper > .companyWrapper > h1
{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(3, 78, 96, 0.5);
    font-family: 'Playlist-script', regular;
    font-weight: 700;
    font-size: 45px;
    color: #ffffff;
}

.ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper > .companyWrapper:hover
{
    transform: scale(1.1);
    transition: transform 330ms ease-out;
}

.ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper > .companyWrapper:hover > h1
{
    visibility: visible;
}


.ourCompaniesWrapper > .colaboracionesCompaniesWrapper
{
    margin-top: -220px;    
    width: 100%;
    background-image: url("../../ressources/images/full/colaboracionesBackground.svg");
    background-size: 1700px;
    background-repeat: no-repeat;
    background-position: 600px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .colaboracionesTitleWrapper
{
    margin-top: 180px;
    margin-right: 230px;
    margin-bottom: 80px;
}

.ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .colaboracionesTitleWrapper > h1
{
    font-family: 'Playlist-script', regular;
    font-weight: 700;
    font-size: 60px;
    color: #0C6980;
    text-align: right;
}

.ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper
{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 60%;
    margin-top: 20px;
    margin-left: 750px;
    margin-bottom: 190px;
}

.ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper > .companyWrapper
{
    height: 400px;
    width: 400px;
    margin: auto;
    z-index: 41;
    cursor: pointer;
    transition: transform 330ms ease-out;
}

.ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper > .companyWrapper > h1
{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(31, 136, 112, 0.4);
    font-family: 'Playlist-script', regular;
    font-weight: 700;
    font-size: 45px;
    color: #ffffff;
}

.ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper > .companyWrapper:hover
{
    transform: scale(1.1);
    transition: transform 330ms ease-out;
}

.ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper > .companyWrapper:hover > h1
{
    visibility: visible;
}

.knowMore
{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 5px;
}

.knowMore > p
{
    text-align: justify;
    font-size: 20px;
    font-family: 'Raleway', regular;
    font-weight: 400;
    color: #000000;
}

.knowMore > img
{
    height: 23px;
    width: 23px;
    margin-left: 10px;
}

.allCompanyDetailsWrapperAdultos
{
    margin-top: -300px;
    margin-bottom: -30px;
    z-index: 10000;
}

.allCompanyDetailsWrapperFamiliar
{
    margin-top: -140px;
    margin-bottom: -80px;
    z-index: 10000;
}

.allCompanyDetailsWrapperColabs
{
    margin-top: -140px;
    margin-bottom: 140px;
    z-index: 10000;
}

@media screen and (max-width: 1600px) {
    .allCompanyDetailsWrapperFamiliar
    {
        margin-top: -280px;
        margin-bottom: 20px;
    }

    .allCompanyDetailsWrapperAdultos
    {
        margin-top: -270px;
        margin-bottom: 0px;
    }

    .allCompanyDetailsWrapperColabs
    {
        margin-top: -290px;
        margin-bottom: 140px;
    }
}



@media screen and (max-width: 1750px) {

    .ourCompaniesWrapper > .titleWrapper{
        width: 40%;
    }
}

@media screen and (max-width: 1600px) {

    .ourCompaniesWrapper > .titleWrapper{
        width: 40%;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 350px;
        width: 350px;
    }
    .ourCompaniesWrapper > .adultCompaniesWrapper
    {
        background-position: -80px -30px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper
    {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 350px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .adultsTitleWrapper
    {
        margin-top: 140px;
        margin-left: 10%;
        margin-bottom: 80px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 400px;
        width: 400px;
    }
    .ourCompaniesWrapper > .familiarCompaniesWrapper
    {
        background-position: -80px 0px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper
    {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 300px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .familiarTitleWrapper
    {
        margin-top: 140px;
        margin-right: 10%;
        margin-bottom: 80px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 350px;
        width: 350px;
    }
    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper
    {
        background-position: 400px 0px;
        margin-top: -280px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper
    {
        margin-left: auto;
        margin-right: 0;
        margin-top: 20px;
        margin-bottom: 350px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .colaboracionesTitleWrapper
    {
        margin-top: 140px;
        margin-left: 10%;
        margin-bottom: 80px;
    }
}

@media screen and (max-width: 1350px) {

    .ourCompaniesWrapper > .titleWrapper{
        width: 40%;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper
    {
        width: 90%;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 300px;
        width: 300px;
    }
    .ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper > .companyWrapper > h1
    {
        font-size: 40px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper
    {
        width: 90%;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 300px;
        width: 300px;
    }
    .ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper > .companyWrapper > h1
    {
        font-size: 40px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 300px;
        width: 300px;
    }
    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper > .companyWrapper > h1
    {
        font-size: 40px;
    }
    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper
    {
        background-position: 400px -40px;
    }

}

@media screen and (max-width: 1110px) {

    .ourCompaniesWrapper > .titleWrapper{
        width: 60%;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper
    {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper
    {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .familiarTitleWrapper
    {
        margin-top: 140px;
        margin-right: 10%;
        margin-bottom: 50px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper
    {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .colaboracionesTitleWrapper
    {
        margin-top: 140px;
        margin-right: 10%;
        margin-bottom: 80px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper
    {
        margin-top: -280px;
        width: 100%;
        background-image: url("../../ressources/images/full/colaboracionesBackground.svg");
        background-size: 1700px;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 10;
    }
}

@media screen and (max-width: 950px) {

    .ourCompaniesWrapper > .titleWrapper{
        width: 60%;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper
    {
        background-image: url("../../ressources/images/full/adultsBackgound.svg");
        background-size: 3500px;
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: -700px 80px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 500px;
        width: 500px;
        margin-bottom: 40px;
    }
    
    .ourCompaniesWrapper > .adultCompaniesWrapper > .adultsTitleWrapper
    {
        margin-top: 80px;
        margin-left: 5%;
        margin-bottom: 30px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper > .companyWrapper > h1
    {
        font-size: 50px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper
    {
        background-image: url("../../ressources/images/full/familiarBackground.svg");
        background-size: 2500px;
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: -300px -20px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 500px;
        width: 500px;
        margin-bottom: 40px;
    }
    
    .ourCompaniesWrapper > .familiarCompaniesWrapper > .familiarTitleWrapper
    {
        margin-top: 40px;
        margin-left: 5%;
        margin-right: auto;
        margin-bottom: 30px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper > .companyWrapper > h1
    {
        font-size: 50px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper
    {
        background-image: url("../../ressources/images/full/colaboracionesBackground.svg");
        background-size: 1600px;
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: -200px -20px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 160px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 500px;
        width: 500px;
        margin-bottom: 40px;
    }
    
    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .colaboracionesTitleWrapper
    {
        margin-top: 40px;
        margin-left: 5%;
        margin-right: auto;
        margin-bottom: 30px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper > .companyWrapper > h1
    {
        font-size: 50px;
    }

}

@media screen and (max-width: 750px) {

    .ourCompaniesWrapper > .titleWrapper{
        width: 80%;
    }

    .ourCompaniesWrapper > .titleWrapper > h1{
        font-size: 50px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .adultsTitleWrapper > h1
    {
        font-size: 45px;
    }
    .ourCompaniesWrapper > .familiarCompaniesWrapper > .familiarTitleWrapper > h1
    {
        font-size: 45px;
    }
    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .colaboracionesTitleWrapper > h1
    {
        font-size: 45px;
    }
}

@media screen and (max-width: 600px) {

    .ourCompaniesWrapper > .titleWrapper{
        width: 80%;
    }

    .ourCompaniesWrapper > .titleWrapper > h1{
        font-size: 45px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .adultsTitleWrapper > h1
    {
        font-size: 40px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 400px;
        width: 400px;
        margin-bottom: 40px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper
    {
        background-size: 3000px;
        background-position: -700px 30px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .familiarTitleWrapper > h1
    {
        font-size: 40px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 400px;
        width: 400px;
        margin-bottom: 40px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper
    {
        background-size: 2000px;
        background-position: -300px -20px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .colaboracionesTitleWrapper > h1
    {
        font-size: 40px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 400px;
        width: 400px;
        margin-bottom: 40px;
    }
    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper
    {
        background-size: 1300px;
        background-position: -200px -20px;
    }
}

@media screen and (max-width: 500px) {

    .ourCompaniesWrapper > .titleWrapper{
        width: 80%;
    }

    .ourCompaniesWrapper > .titleWrapper > h1{
        font-size: 45px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .adultsTitleWrapper > h1
    {
        font-size: 40px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 350px;
        width: 350px;
        margin-bottom: 40px;
    }

    .ourCompaniesWrapper > .adultCompaniesWrapper
    {
        background-size: 3000px;
        background-position: -700px 30px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .familiarTitleWrapper > h1
    {
        font-size: 39px;
        text-align: left;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 350px;
        width: 350px;
        margin-bottom: 40px;
    }

    .ourCompaniesWrapper > .familiarCompaniesWrapper
    {
        background-size: 2000px;
        background-position: -300px -20px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .colaboracionesTitleWrapper > h1
    {
        font-size: 40px;
    }

    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper > .companiesWrapper > .companyWrapper
    {
        height: 350px;
        width: 350px;
        margin-bottom: 40px;
    }
    .ourCompaniesWrapper > .colaboracionesCompaniesWrapper
    {
        background-size: 1300px;
        background-position: -200px -20px;
    }
}